<template>
  <Companies :select-company="selectCompany">
    <template v-slot:buttons>
      <router-link to="/company-management/new">
        <v-btn color="success">
          <v-icon left>add</v-icon>
          Neuer Partner
        </v-btn>
      </router-link>
    </template>
  </Companies>
</template>

<script>
import Companies from '../../components/partials/Companies.vue'

export default {
  methods: {
    selectCompany(id) {
      this.$router.push({
        name: 'company.general',
        params: {
          id,
        },
      })
    },
  },
  components: {
    Companies,
  },
}
</script>
